
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
em-emoji-picker {
  --shadow: none;
  --border-radius: 12px;
  --category-icon-size: 12px;
  --font-size: calc(var(--font-scale) * 14px);
  width: 100%;
  max-height: 100%;
}

