
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.slider {
  position: relative;
  max-width: 100%;
  display: flex;
  overflow: hidden;
  touch-action: none;

  @include isMouse() {
    &:hover {
      .btn {
        display: block;
      }
    }
  }
}

.slides {
  padding: 0 25px;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  touch-action: none;

  > * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;
    user-select: none;
  }
}

.slidesAnim {
  transition: all 0.5s;
}

.btn {
  display: none;
  position: absolute;
  top: 50%;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0.5;


  &::before {
    content: '';
    position: absolute;

    transform: translateY(-50%);
    width: 7px;
    height: 12px;
    background-image: url('images/arrow.svg');
    background-size: contain;
    background-position: center;

  }

  &.btnLeft {
    left: 15px;
    transform: translateY(-50%) rotate(180deg);
  }

  &.btnRight {
    right: 15px;
  }

  &.active {
    opacity: 1;
    cursor: pointer;
  }
}