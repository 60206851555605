
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.container {
  position: fixed;
  padding: 8px 0;
  margin: 0;
  width: 500px;
  max-width: calc(100% - 32px);
  height: 400px;
  display: flex;
  flex-flow: column;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(37, 45, 60, 0.14);
  overflow: hidden;
  z-index: 100;

  @include isTouch() {
    -webkit-touch-callout: default !important;
    -webkit-user-select: none !important;
    * {
      -webkit-touch-callout: default !important;
      -webkit-user-select: none !important;
    }
  }
}

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.menuItem {
  color: $text-tertiary;
  font-weight: 700;
  padding: 8px 16px;
  cursor: pointer;

  &.active {
    color: $main-color;
    cursor: default;
  }
}

.emojiBox {
  display: flex;
  overflow: hidden;
  > div {
    flex: 1;
  }
}

.storeBox {
  flex: 1;
}

.stickerBox {
  padding: 8px 16px;
  overflow: hidden;
}
