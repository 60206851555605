
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.container {
  min-height: 52px;
  padding: 0 52px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  font-size: calc(var(--font-scale) * 16px);
  overflow: hidden;

  &.editContainer {
    padding-right: 32px;
  }

  &.nextContainer {
    padding-right: 32px;
  }

  &.notMove {
    padding-left: 18px;
  }

  @include isMouse() {
    &:not(.notHover):hover {
      background: $bg-second;
    }
  }

  &.asButton {
    cursor: pointer;
  }

  span.text {
    margin: 14px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.highlighted {
    background: $bg-second;
  }
  &.notHighligted {
    background: inherit;
    &:hover {
      background: inherit;
    }
  }
}

.icon,
.textIcon,
.svgIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: $text-tertiary;
}

.icon {
  font-size: calc(var(--font-scale) * 20px);
}

.textIcon {
  font-size: calc(var(--font-scale) * 18px);
}

.svgIcon {
  left: 12px;
  width: 18px;
  height: 18px;
}

.button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: $main-color;

  :active {
    opacity: .6;
  }

  &.disabled {
    filter: grayscale(1);
  }
}

.editBtn {
  background: $main-color;
  border-radius: 8px;
}

.copyBtn {
  color: $main-color;
  font-size: calc(var(--font-scale) * 24px);
}

.copiedBtn {
  color: green;
  font-size: calc(var(--font-scale) * 24px);
  i:active {
    opacity: 1;
  }
}

.nextButton {
  font-size: calc(var(--font-scale) * 24px);
}

.input.input {
  position: relative;
  left: -17px;
  border: 1px solid $input-border;
  border-radius: 8px;
  padding: 9px 16px 10px;
  font-size: calc(var(--font-scale) * 16px);
  font-family: inherit;
  flex: 1;
  outline: none;
  &:focus, &:hover, &:focus-visible, &:active {
    border-color: $input-border;
  }
}
