
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.cropper {
  display: flex;
  flex-flow: column;
}

.imageBox {
  height: 238px;
  width: 100%;
  margin-bottom: 32px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.input {
  width: 100%;
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  border-radius: 8px;
  z-index: 2;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0 0;
    border-radius: 50%;
    cursor: pointer;
    z-index: 4;
    background: #fff;
    border: 6px solid $main-color !important;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: linear-gradient(90deg, $text-primary, $text-primary var(--progress-value, 0), #C4C4C4 var(--progress-value, 0));
    content: ""
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    margin: - 8px 0 0;
    border-radius: 50%;
    cursor: pointer;
    z-index: 4;
    background: #fff;
    border: 6px solid $main-color !important;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: #C4C4C4;
  }

  &::-moz-range-progress {
    height: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: $text-primary;
  }
}
