
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover .link {
    text-decoration: underline;
  }
}

.preloader {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.link {
  color: $link-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  max-width: 100%;
  color: $text-primary;
  font-size: calc(var(--font-scale) * 14px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  margin-right: 8px;
  width: 32px;
  height: 40px;
}

.infoBox {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow: hidden;
}

.btnBox {
  display: flex;
  justify-content: flex-start;
}

.linkBtn {
  padding: 0;
  font-size: calc(var(--font-scale) * 12px);
  color: $link-color;
}
