
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.description {
  margin: 8px 0;
}

.search {
  margin-bottom: 8px;
}

.amount {
  float: right;
}

.buttons {
  margin-top: 8px;
  display: flex;

  > button:nth-child(n+2) {
    margin-left: 16px;
  }
}

.dropzone {
  margin-bottom: 16px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px dashed $main-color;
  cursor: pointer;

  &.reject {
    border-color: #c52222;
  }
}

.image {
  width: 56px;
  height: 56px;
  margin-right: 16px;
  border-radius: 50%;
}

.input {
  width: 100%;
}

.removeBtn {
  margin-left: auto;
}

.preloader.preloader {
  min-height: 300px;
}

@supports (-webkit-touch-callout: none) {
  .createChatGroupClassName {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    overflow: hidden;
  }
}

.chips {
  margin-bottom: 12px;
}

