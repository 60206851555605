
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.body {
  padding: 44px 16px 16px;
  display: flex;
  flex-flow: column;
  position: absolute;
  background-color: $text-primary;
  color: $bg-primary;
  border-radius: 24px;
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  color: inherit;
  font-size: 24px;
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
  border-left-color: $text-primary;
  border-bottom-color: $text-primary;

  &.top {
    transform: translateY(calc(-50% - 1px)) rotate(-45deg);
  }

  &.right {
    transform: translateX(calc(-50% + 1px)) rotate(45deg);
  }

  &.bottom {
    transform: translateY(calc(-50% + 1px)) rotate(135deg);
  }

  &.left {
    transform: translateX(calc(-50% - 1px)) rotate(-135deg);
  }
}
