
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.panel {
  padding: 15px 10px 15px 16px;
  display: flex;
}

.search {
  flex: 1;
}

.button {
  margin-left: 4px;
  font-size: calc(var(--font-scale) * 24px);
}
