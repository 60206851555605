
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.title {
  margin: 16px 0 8px;
  font-size: calc(var(--font-scale) * 18px);
  line-height: 1.2;
  font-weight: 700;
}

.desc {
  margin: 0 0 24px;
  color: $text-secondary;
}

.radioButtons.radioButtons {
  margin-bottom: 24px;
}

.field {
  margin-bottom: 16px;
}

.qrBox {
  padding: 16px;
  display: flex;
  background: $bg-second;
  border-radius: 16px;
}

.qrInfo {
  width: calc(50% - 8px);
  display: flex;
  flex-flow: column;
}

.qrTitle {
  margin: 0 0 8px;
  font-size: calc(var(--font-scale) * 21px);
  line-height: 1.2;
  font-weight: 700;
}

.qrDesc {
  margin: 0 0 16px;
  line-height: 1.5;
  color: $text-secondary;
}

.qrLink {
  position: relative;
  margin: auto 0 0;
  padding-left: 32px;
  color: $link-color;
  line-height: 1.5;
  font-weight: 700;
  cursor: pointer;

  & svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: url("/images/icons/share.svg") center / contain no-repeat ;
    color: $link-color;
  }
}

.qrImg {
  width: calc(50% - 8px);
  height: auto;
  margin-left: auto;
  align-self: flex-start;
}
