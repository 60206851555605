
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.avatar{
  display: block;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.textBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  font-size: calc(var(--font-scale) * 18px);
  font-weight: 700;
}

.small {
  height: 24px;
  width: 24px;
  font-size: calc(var(--font-scale) * 14px);
}

.medium {
  height: 96px;
  width: 96px;
  font-size: calc(var(--font-scale) * 36px);
}

.big {
  height: 192px;
  width: 192px;
  font-size: calc(var(--font-scale) * 64px);
}
