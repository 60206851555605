
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.iconButton {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 6px;

  @include isTouch() {
    -webkit-user-select: none !important;
  }

  &.disabled {
    cursor: default;
  }
}
