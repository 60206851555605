
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.user {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.name {
  margin: 0;
  font-size: calc(var(--font-scale) * 14px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tick {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 7px;
  left: 30px;
  color: #fff;
  border-radius: 50%;
  background: $attention-color;
}

.context {
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-left: 16px;
}

.description {
  margin: 0;
  font-size: calc(var(--font-scale) * 12px);
}

.icon {
  font-size: 22px;
}

.groupIcon {
  margin-right: 4px;
}

.sendIcon {
  margin: 0 20px 0 auto;
  color: $main-color;
}
