/* noto-sans-sc-100 - chinese-simplified */
//@font-face {
//  font-family: 'Noto Sans SC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 100;
//  src: url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-100.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans SC Thin'), local('NotoSansSC-Thin'),
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-100.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-100.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-sc-300 - chinese-simplified */
//@font-face {
//  font-family: 'Noto Sans SC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 300;
//  src: url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-300.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans SC Light'), local('NotoSansSC-Light'),
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-300.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-300.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-sc-regular - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('./noto-sans-sc/noto-sans-sc-v10-chinese-simplified-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans SC Regular'), local('NotoSansSC-Regular'),
  url('./noto-sans-sc/noto-sans-sc-v10-chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./noto-sans-sc/noto-sans-sc-v10-chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./noto-sans-sc/noto-sans-sc-v10-chinese-simplified-regular.woff') format('woff'); /* Modern Browsers */
}
/* noto-sans-sc-500 - chinese-simplified */
//@font-face {
//  font-family: 'Noto Sans SC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 500;
//  src: url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-500.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans SC Medium'), local('NotoSansSC-Medium'),
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-500.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-500.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-sc-700 - chinese-simplified */
//@font-face {
//  font-family: 'Noto Sans SC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 700;
//  src: url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-700.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans SC Bold'), local('NotoSansSC-Bold'),
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-700.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-700.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-sc-900 - chinese-simplified */
//@font-face {
//  font-family: 'Noto Sans SC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 900;
//  src: url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-900.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans SC Black'), local('NotoSansSC-Black'),
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-900.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-sc/noto-sans-sc-v10-chinese-simplified-900.woff') format('woff'); /* Modern Browsers */
//}
