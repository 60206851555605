
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.table {
  border: none;
  width: 100%;
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
}
.thead {
  font-size: small;
}
.td {
  width: 25%;
}
.tr:nth-child(even) {
  background-color: #dedede;
}
