
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: fixed;
  bottom: 20px;
  left: max(calc(50% - 150px), 0);
  width: 300px;
  padding: 10px 15px;
  background: rgba(#000, 0.8);
  color: #fff;
  border-radius: 10px;
  overflow: auto;
}

.text {
  margin: 0 0 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
