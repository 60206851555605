
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  $connect-animation-time: 1s;
  $connect-icon-width: 4px;
  position: relative;
  width: 20px;
  height: 16px;

  &:after,
  &:before {
    position: absolute;
    content: "";
    height: 50%;
    width: $connect-icon-width;
    top: 50%;
    transform: translateY(-50%);
    background-color: $main-color;
    border-radius: 16px;
    animation: connect-secondary-animation $connect-animation-time linear infinite;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  .item {
    position: absolute;
    height: 100%;
    width: $connect-icon-width;
    background-color: $main-color;
    border-radius: 16px;
    top: 50%;
    left: calc(50% - $connect-icon-width / 2);
    transform: translateY(-50%);
    animation: connect-main-animation $connect-animation-time linear infinite;
  }
}

@keyframes connect-main-animation {
  0% {
    height: 100%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
}

@keyframes connect-secondary-animation {
  0% {
    height: 50%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 50%;
  }
}
