
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.langList {
  list-style-type: none;
  margin: 24px 0;
  padding: 0;
  li {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    &:hover {
      background-color: $bg-second;
    }
    i {
      color: $main-color
    }
  }
}
