
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.slider {
  margin: 0 16px;
}

.contact {
  max-width: 110px;
  margin: 0 10px;
  padding: 5px 10px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.avatar {
  margin-bottom: 4px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.nickname {
  max-width: 110px;
  font-size: calc(var(--font-scale) * 14px);
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.incoming,
  &.outgoing {
    padding-right: 13px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 9px;
      height: 9px;
      transform: translateY(-50%);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.incoming::before {
    background-image: url('/images/icons/incoming-arrow.svg');
  }

  &.outgoing::before {
    background-image: url('/images/icons/outgoing-arrow.svg');
  }
}

.groupName {
  padding: 8px 16px;
  font-size: calc(var(--font-scale) * 14px);
  line-height: 21px;
  font-weight: 700;
  color: $text-secondary;
}
