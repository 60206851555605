
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.contextMenu {
  position: fixed;
  padding: 0;
  margin: 0;
  min-width: 196px;
  list-style: none;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(37, 45, 60, 0.14);
  overflow: hidden;
  z-index: 100;

  @include isTouch() {
    -webkit-touch-callout: default !important;
    -webkit-user-select: none !important;
    * {
      -webkit-touch-callout: default !important;
      -webkit-user-select: none !important;
    }
  }
}

.menuItem {
  margin: 0;
  padding: 8px;
  display: flex;
  align-items: flex-end;
  font-size: calc(var(--font-scale) * 16px);
  line-height: 1.5;
  cursor: pointer;

  &:last-child {
    padding-bottom: 16px;
  }

  &:first-child {
    padding-top: 16px;
  }

  &:hover {
    background: rgba(#ccc, .2);
  }

  &:active {
    background: rgba(#ccc, .5);
  }

  &.disabled {
    cursor: default;
    opacity: .7;
    color: $text-placeholder;
    .imgIcon {
      color: $text-placeholder;
    }
  }
}

.ico {
  width: 25px;
  font-size: calc(var(--font-scale) * 24px);
  margin-left: 4px;
  margin-right: 8px;
  align-self: center;
  cursor: pointer;
}

.svgIcon {
  width: 24px;
  height: 24px;
  align-self: center;
  margin-left: 4px;
  margin-right: 8px;
}
