
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.pinAutoDelete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-second;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $text-secondary;
  font-size: calc(var(--font-scale) * 14px);
  font-weight: normal;
  padding: 8px 16px;
  div {
    display: flex;
    justify-content: center;
    flex: 1;
  }
  i {
    cursor: pointer;
  }
}
