
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  list-style: none;

  &.disable {
    filter: grayscale(1);
  }
}

.item {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.active {
    cursor: default;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .disable & {
    cursor: default;
  }
}

.svgIcon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.title {
  margin-right: 10px;
  line-height: 1.5;
  color: $text-primary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tick {
  position: relative;
  margin-left: auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid $input-border;

  &.active {
    &::before {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      background: $main-color;
    }
  }
}
