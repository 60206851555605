
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
}

.message {
  display: flex;
  flex-flow: column;
  position: relative;
  min-width: 200px;
  max-width: 90%;
  margin-left: auto;
  border-radius: 16px 0 16px 16px;
  padding: 5px 16px 22px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  background: #fff;
  border: 1px solid $main-color;

  &.incoming {
    margin-left: 0;
    border-radius: 0 16px 16px 16px;
    border-color: $bg-second;
    background: $bg-second;
  }
}

.avatarBox {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.avatar {
  margin-right: 8px;
}

.replierName {
  font-size: calc(var(--font-scale) * 16px);
  font-weight: 700;
}

.date {
  position: absolute;
  right: 10px;
  bottom: 4px;
  font-size: calc(var(--font-scale) * 12px);
}

.imageList {
  margin-top: 8px;
  margin-left: auto;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-flow: wrap;
  list-style: none;
}

.imageItem {
  margin-left: 8px;
  margin-bottom: 8px;
  width: 80px;
  height: 80px;
}

.image {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
