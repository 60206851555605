
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  padding: 0 16px;
  display: flex;
  flex-flow: column;
}

.preloaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoBox {
  display: flex;
  align-items: center;
  min-height: 52px;
}

.packIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.packName {
  margin-left: 8px;
}

.btn {
  margin-left: auto;
}

.stickers {
  margin: 0;
  padding: 8px 0 0;
  display: flex;
  overflow: auto;
  max-width: 100%;
  list-style: none;
}

.sticker {
  width: 46px;
  height: 46px;
  object-fit: cover;
  border-radius: 50%;
}
