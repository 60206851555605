
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.panelWrapper {
  margin: 32px 16px;
  padding: 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: $bg-second;
  border-radius: 16px;
}

.title {
  margin: 0 0 24px;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  font-weight: 700;
  color: $text-secondary;
}

.buttons.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  > :global(.button) {
    padding: 4px 16px;
    font-size: calc(var(--font-scale) * 12px);
    line-height: 1.5;
  }

  > :global(.secondaryButton) {
    padding: 3px 16px;
  }

  > button:nth-child(n+2) {
    margin-left: 16px;
  }
}
