
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
}

.search {
  margin-bottom: 16px;
}

.btn {
  margin-bottom: 16px;
  flex: 1;
}
