
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.document {
  margin: 0 auto;
}

.documentBox {
  width: 100%;
  padding: 0 16px;
  display: flex;
  overflow: auto;
}

.pageBox {
  display: flex;
  flex-flow: column;
  align-items: center;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;

  > div {
    max-width: 100%;
    border-bottom: 30px solid #888;
  }
}

.loadText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: calc(var(--font-scale) * 20px);
  line-height: 1.2;
  font-weight: bold;
  text-shadow: 1px 1px 3px #000;
}

.docxBox {
  width: 100%;
  max-width: 1024px;
  padding: 10px;
  background: #fff;
  overflow: auto;
}

