
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.input {
  padding: 11px 16px;
  background: #fff;
  border: 1px solid $input-border;
  border-radius: 16px;
  outline: none;
  width: 100%;

  @include isMouse() {
    &:hover {
      border-color: $main-color;
    }
  }

  &:focus-within
  {
    border-color: $text-secondary;
  }

  &[disabled] {
    background: $bg-third;
    &:hover,
    &:focus-within {
      border-color: $input-border;
    }
  }
}
