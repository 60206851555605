
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.reply {
  padding: 8px 5px 8px 8px;
  position: relative;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;
  @include isTouch() {
    -webkit-touch-callout: default !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    * {
      -webkit-touch-callout: default !important;
      -webkit-user-select: none !important;
      user-select: none !important;
    }
    img {
      pointer-events: none;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 2px;
    height: calc(100% - 16px);
    position: absolute;
    top: 8px;
    left: 0;
    background: $main-color;
  }

  &.anim::before {
    background: linear-gradient(-45deg, $main-color, #fff, $main-color, #fff);
    background-size: 100% 400%;
    animation: gradient 2s linear infinite;
  }
}

@keyframes gradient {
  0% {
    background-position: 50% 0% ;
  }
  100% {
    background-position: 50% 100%;
  }
}

.content {
  max-width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;
}

.userName,
.text {
  margin: 0;
  max-width: calc(100%);
  color: $text-primary;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userName {
  font-weight: 700;
}

.text{
  color: $text-secondary;
}

.textType {
  text-transform: capitalize;
}

.image {
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  margin-right: 8px;
  object-fit: cover;
}

.fileImgBox {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $main-color;

  > svg {
    width: 10px;
    height: 13px;
  }
}
