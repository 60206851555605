
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.sender,
.header {
  margin-bottom: 16px;
}


