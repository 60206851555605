
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  align-items: center;
  padding: 0 0 4px;

  .btn {
    position: relative;
    margin-right: 4px;
    padding: 0;
    width: 32px;
    height: 32px;
    background: $main-color;
    border-radius: 50%;
    flex: 0 0 auto;

    &.disabled {
      background: $text-placeholder;
    }
  }
}

.barBox {
  position: relative;
  height: 24px;
}

.seekInput {
  position: absolute;
  width: 100%;
  height: 10px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.seekInput::-webkit-slider-runnable-track {
  height: 40px;
}
.seekInput::-moz-range-track {
  height: 40px;
}
.seekInput::-ms-track {
  height: 40px;
}

.duration {
  margin-left: 4px;
  font-size: calc(var(--font-scale) * 12px);
  color: $message-text;
}

.bottom {
  display: flex;
  align-self: flex-end;
  .time {
    font-size: calc(var(--font-scale) * 10px);
    line-height: 1.33;
    color: $text-primary;
    margin-left: auto;
    .chatPin {
      font-size: calc(var(--font-scale) * 12px);
      margin: 0 6px 0 0;
      color: black;
    }
  }
  i {
    margin-left: 4px;
    color: $main-color;
    align-self: center;
    .chat-check {
      color: #252d3c;
    }
  }
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
}
