
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.container {
  transform: rotate(-90deg);
}

.progress {
  fill: none;
  stroke-dasharray: 100 100;
  stroke-width: 2px;
  transition: stroke-dashoffset 0.1s ease-in-out;
  stroke-dashoffset: 100;
}

.infinityProgress {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
