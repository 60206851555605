
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.header {
  grid-area: chatHeader;
  display: flex;
  padding: 9px 16px 9px 0;
  border-bottom: 2px solid #ebf0f4;
  overflow: hidden;
  .avatar {
    border-radius: 50%;
    flex-shrink: 0;
    height: 48px;
    margin: 0 16px;
    width: 48px;
  }
  .infoBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1 1;
    overflow: hidden;
    div {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .title {
      font-size: calc(var(--font-scale) * 21px);
      font-weight: 700;
      line-height: 120%;
    }
    .description {
      font-size: calc(var(--font-scale) * 12px);
      line-height: 133%;
      margin-top: 2px;
      color: $text-tertiary;
    }
  }
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: messages;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  img {
    border-radius: 50%;
    height: 290px;
    margin-bottom: 32px;
    width: 290px;
  }
  h4 {
    font-size: calc(var(--font-scale) * 24px);
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 32px;
  }
  .sendButton {
    background-color: $main-color;
    border: none;
    border-radius: 16px;
    color: #fff;
    cursor: pointer;
    line-height: 24px;
    padding: 12px 24px;
    text-decoration: none;
  }
}

.backButton {
  padding-left: 16px;
  padding-right: 16px;
}
