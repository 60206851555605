
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.tabPanel {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  list-style: none;
}

.tabItem {
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid $bg-third;
  border-radius: 16px;
  margin-right: 8px;
  color: $text-tertiary;

  > span {
    padding: 0 5px;
    position: relative;
  }

  &.active {
    cursor: default;
    background: $main-color;
    border-color: transparent;
    color: #fff;
  }
}
