
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.imageWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 150px;
  font-weight: 700;
  width: 300px;
  max-width: 100%;
  border-radius: 8px;
  &.active {
    cursor: pointer;
  }
}

.image {
  max-width: 100%;
  width: 300px;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  align-self: center;
  border-radius: 8px;

  &.active {
    cursor: pointer;
  }
}

.avatarEdit {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: $text-primary;
  right: 24px;
  top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  visibility: hidden;

  .imageWrapper:hover &,
  &Active {
    visibility: visible;
  }

  > i {
    color: #fff;
    font-size: calc(var(--font-scale) * 14px);
  }

  @include isTouch() {
    width: 30px;
    height: 30px;
    visibility: visible;
  }
}

.transparentBg {
  background: transparent;
}
