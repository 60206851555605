
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.modal {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, .7);
  z-index: 9999;
  transition: all ease-in .15s;
  pointer-events: auto;
}

.inside {
  background: transparent;
}

.body {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  max-width: 487px;
  width: calc(100% - 40px);
  @include respond-to(phone) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
