
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  position: fixed;
  z-index: 10;

  width: 100%;
  height: 100%;
  background: rgba(#000, 0.4);
}
