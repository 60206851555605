
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.modalTitle {
  min-height: 66px;
  color: $message-text;
  font-weight: 700;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid $bg-primary;
  font-size: calc(var(--font-scale) * 21px);
  line-height: 1.2;
}

.titleText {
  color: $text-primary;
  margin: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(var(--font-scale) * 21px);
  font-weight: bold;
}

.iconButton {
  font-size: inherit;
  line-height: inherit;
}

.backButton {
  margin-right: 16px;
}
