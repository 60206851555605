/* roboto-300 - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900italic - cyrillic_latin-ext_cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  src: url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./roboto/roboto-v20-cyrillic_latin-ext_cyrillic-ext_latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
