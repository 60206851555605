
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
$animation-time: 0.3s;
$photoSize: 400px;
$photoMarginTop: 6px;
$photoMarginBottom: 6px;
$fieldSize: 52px;
$containerPadding: 24px;

.box {
  min-height: 66px;
}

.container {
  display: flex;
  flex-flow: column;
  overflow: hidden;

  &.mobile {
    display: grid;
    grid-template-rows: 1fr 68px;
  }
}

.context {
  display: flex;
  flex-flow: column;
}

.preloader {
  min-height: 252px;
}

.inputProfile {
  min-height: 52px !important;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;

  &.compressed {
    & input {
      margin-left: 13px;
      padding-left: 3px;
    }
  }
}

.navTabsBox {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 68px;
  border-top: $chat-area-border;
  border-right: $chat-area-border;
  background: #fff;
}

.navTabs {
  width: 100%;
  border-top: $chat-area-border;
  border-right: $chat-area-border;
}

.insideModal {
  background: transparent;
}

.section {
  $containerPadding: 24px;
  padding: 2px 0 10px;
  position: relative;
}

.animBox {
  display: flex;
  position: relative;
  min-height: 52px;
  max-height: 52px;
  height: $photoSize + $fieldSize + $photoMarginTop + $photoMarginBottom;
  transition: all $animation-time linear;

  &.active {
    cursor: pointer;
  }

  &.big {
    max-height: min($photoSize + $fieldSize + $photoMarginTop + $photoMarginBottom,
    calc(100vw + $fieldSize + $photoMarginTop + $photoMarginBottom - 2 * $containerPadding));
  }
}

.avatar.avatar {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  height: min($photoSize, 100vw - 2 * $containerPadding);
  width: min($photoSize, 100vw - 2 * $containerPadding);
  max-height: 48px;
  max-width: 48px;
  transform: translate(0, -50%);
  transition: all $animation-time linear;
  cursor: pointer;

  &.big {
    max-height: min($photoSize, 100vw - 2 * $containerPadding);
    max-width: min($photoSize, 100vw - 2 * $containerPadding);
    border-radius: 8px;
    top: $photoMarginTop;
    left: 15.5px;
    font-size: 150px;
    transform: translate(0, 0);
    @include respond-to(phone) {
      left: max((100vw - 2 * $containerPadding) / 2 - $photoSize / 2, 0px);
    }
  }
}

.photoEditor.photoEditor {
  position: absolute;
  top: $photoMarginTop;
  left: 0;
  width: 100%;
  max-height: min($photoSize + $fieldSize + $photoMarginTop + $photoMarginBottom,
  calc(100vw - 2 * $containerPadding));;
}

.infoGroup {
  padding-left: 52px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
}

.groupNameText {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settingsBtn {
  margin-left: auto;
  margin-right: 8px;
}

.fieldBox {
  position: relative;
}

.counter {
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  background: $main-color;
  border-radius: 10px;
  color: #fff;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1;
  z-index: 1;
}
