
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.cropperBox {
  padding: 32px 32px 20px;
  min-height: 250px;
}

.buttons {
  display: flex;
  flex-flow: column;
  margin-top: 24px;

  > button:nth-child(n+1) {
    margin-top: 8px;
  }
}
