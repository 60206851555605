
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.services {
  position: relative;
  grid-area: chatList;
  display: flex;
  flex-flow: column;
  flex: 1 1;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;

    .service {
      padding: 8px 16px;
      display: grid;
      grid-template-areas:
        "avatar name"
        "avatar link";
      grid-template-columns: 48px 1fr;
      column-gap: 16px;
      border-left: 2px solid transparent;
      min-height: 64px;
      cursor: pointer;

      @include isMouse() {
        &:hover {
          background: $bg-second;
        }
      }

      .activeService {
        border-left-color: #1FAEFF;
      }

      .avatar {
        width: 48px;
        height: 48px;
        grid-area: avatar;
        border-radius: 50%;
        align-self: center;
      }

      .name {
        margin: 0;
        grid-area: name;
        font-size: calc(var(--font-scale) * 16px);
        line-height: 1.5;
        font-weight: bold;
        align-self: end;
      }

      .link {
        margin: 0;
        grid-area: link;
        font-size: calc(var(--font-scale) * 12px);
        line-height: 1.33;
        color: $text-tertiary;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .activeService {
      border-left-color: #1FAEFF;
    }
  }
}
