@font-face {
  font-family: 'Noto Color Emoji';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Noto Color Emoji'),
  url('./emoji/NotoColorEmoji.woff2') format('woff2');
}

@font-face {
  font-family: 'EmojiMart';
  src: local("Noto Color Emoji"),
  url('./emoji/NotoColorEmoji.woff2') format('woff2');
}
