
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.block {
  display: flex;
  margin-left: 16px;
  flex: 1 0 auto;
}
.refresh {
  cursor: pointer;
  display: flex;
  margin: auto 15px auto 12px;
}
.img {
  width: auto;
  max-width: calc(100% - 27px);
  min-width: 70px;
  flex: 0 1 auto;
  object-fit: contain;
}
