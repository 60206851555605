
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  min-height: 36px;
  align-items: center;
}

.textIcon {
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-tertiary;
  font-size: calc(var(--font-scale) * 14px);

  &.big {
    font-size: calc(var(--font-scale) * 24px);
  }
}

.slider.slider {
  height: 30px;
  margin-right: 10px;

  :global(.rc-slider-rail) {
    margin: 0;
    background-color: $input-border;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
  }

  :global(.rc-slider-handle) {
    margin: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $input-border;
    top: 50%;
    box-shadow: 0 4px 10px rgba(#000, .1);
    transform: translate(-50%, -50%) !important;
    opacity: 1;

    &:active,
    &:focus-visible,
    &:global(.rc-slider-handle-dragging.rc-slider-handle-dragging) {
      border: 2px solid $input-border;
      box-shadow: 0 4px 10px rgba(#000, .1);
      outline: none;
    }

    &:hover {
      border: 1px solid $input-border;
      box-shadow: 0 4px 10px rgba(#000, .1);
    }
  }

  :global(.rc-slider-dot) {
    width: 1px;
    height: 12px;
    border: none;
    border-radius: 0;
    top: 50%;
    background-color: $input-border;
    transform: translate(-50%, -50%) !important;
  }

  :global(.rc-slider-step) {
    top: 50%;
    transform: translateY(-50%);
  }

  :global(.rc-slider-track) {
    top: 50%;
    transform: translateY(-50%);
  }
}
