
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
}

.label {
  margin-bottom: 4px;
  font-size: calc(var(--font-scale) * 12px);
}
