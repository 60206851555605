
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.voiceBar {
  width: auto;
  > line {
    stroke: $input-border;

    &.trackBefore {
      stroke: $main-color;
    }
  }
}
