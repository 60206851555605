
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  max-width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

.selector {
  width: 100%;
  display: flex;
  flex: 1 0 100%;
}

.list {
  width: 100%;
  max-height: 170px;
  padding: 0;
  position: absolute;
  display: flex;
  flex-flow: column;
  list-style: none;
  top: calc(100% + 4px);
  left: 0;
  border-radius: 16px;
  border: 1px solid $input-border;
  background: #fff;
  overflow: auto;
}

.listItem {
  padding: 4px 8px;
  cursor: default;
  max-width: 100%;

  > span {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none !important;
  }

  @include isMouse() {
    &:hover {
      background: rgba($main-color, 0.5);
    }
  }
}

.hiddenList {
  position: absolute;
  z-index: -1;
  padding: 8px 36px 8px 8px;
}

.selectorTitle {
  width: 100%;
  position: relative;
  padding: 8px 36px 8px 8px;
  border-radius: 16px;
  border: 1px solid $input-border;
  cursor: default;
  -webkit-user-select: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none !important;

  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    fill: #0c161e;
    transition: transform .1s linear;
  }

  .disabled &{
    background-color: #e2eaf0;
  }

  &.opened .arrow {
    transform: translateY(-50%) scaleY(-1);
  }
}

.label {
  margin-bottom: 4px;
  font-size: calc(var(--font-scale) * 12px);;
}
