
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.status {
  padding: 2px 8px;
  border-radius: 8px;
  font-size: calc(var(--font-scale) * 12px);

  &.closed {
    background: #ffcbc9;
  }

  &.opened {
    background: #c4ebcd;
  }
}
