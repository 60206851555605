
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.contextWrapper {
  display: flex;
  flex-flow: column;
}

.description {
  margin: 0;
  color: $text-secondary;
  font-size: calc(var(--font-scale) * 14px);
}

.buttons {
  display: flex;
  margin-top: 16px;

  > button:nth-child(n+2) {
    margin-left: 16px;
  }
}

.noResults {
  margin-top: 4px;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  color: $warning-color;
}

@supports (-webkit-touch-callout: none) {
  .addContactClassName {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    overflow: hidden;
  }
}
