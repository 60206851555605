
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.error {
  padding: 8px 12px;
  margin-bottom: 16px;
  border: 1px solid #f5c2c7;
  border-radius: 0.25rem;
  color: #842029;
  background-color: #f8d7da;
}

.description {
  margin-bottom: 16px;
}
