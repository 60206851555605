
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.searchBox {
  width: 100%;
  display: flex;
}

.search {
  flex: 1;
}

.icon {
  margin-left: 4px;
  font-size: calc(var(--font-scale) * 24px);
}
