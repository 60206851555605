
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    left: 0;
    top: 10px;
    display: block;
    background: $main-color;
    --mask:
      radial-gradient(9.49px at 50% calc(100% + 6.00px),#0000 calc(99% - 2px),#000 calc(101% - 2px) 99%,#0000 101%) calc(50% - 12px) calc(50% - 4px + .5px)/24px 8px repeat-x,
      radial-gradient(9.49px at 50% -6px,#0000 calc(99% - 2px),#000 calc(101% - 2px) 99%,#0000 101%) 50% calc(50% + 4px)/24px 8px repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }

  &::after {
    top: auto;
    bottom: 10px;
  }
}
