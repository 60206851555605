
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.list {
  display: flex;
  flex-flow: column;
  padding: 0;
  list-style: none;
}

.message {
  margin-bottom: 16px;
}

.loaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader {
  width: 40px;
  height: 40px;
}
