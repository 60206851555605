@import "src/styles/fonts";

* {
  box-sizing: border-box;
}

button, input, optgroup, select, textarea {
  font-size: 100%;
}

:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, 'Noto Sans SC', 'Noto Sans TC', 'Noto Sans KR', 'Noto Sans JP', "Noto Color Emoji", sans-serif;
  font-size: calc(var(--font-scale) * 16px);
  line-height: 1.5;
  color: $text-primary;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  background: $bg-primary;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: 100;
}

ul {
  margin: 0;
}
