
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.list {
  min-height: 250px;
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  list-style: none;
}

.item {
  margin-bottom: 16px;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.name {
  margin-left: 16px;
  font-size: calc(var(--font-scale) * 16px);
  line-height: 1.5;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closeBtn {
  margin-left: auto;
  font-size: calc(var(--font-scale) * 20px);
}
