@font-face {
  font-family: 'Chat_Glyph';
  src: url('./chat-glyph/Chat_Glyph.eot');
  src: url('./chat-glyph/Chat_Glyph.eot') format('embedded-opentype'),
  url('./chat-glyph/Chat_Glyph.woff2') format('woff2'),
  url('./chat-glyph/Chat_Glyph.woff') format('woff'),
  url('./chat-glyph/Chat_Glyph.ttf') format('truetype'),
  url('./chat-glyph/Chat_Glyph.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="chat-"], [class*=" chat-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  //noinspection CssNoGenericFontName
  font-family: 'Chat_Glyph' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chat-statistics:before {
  content: "\e954";
}
.chat-autodelete:before {
  content: "\e953";
}
.chat-msg-mute:before {
  content: "\e951";
}
.chat-msg-unmute:before {
  content: "\e952";
}
.chat-info-background:before {
  content: "\e94d";
}
.chat-fullscreen2:before {
  content: "\e94a";
}
.chat-moon:before {
  content: "\e948";
}
.chat-sun:before {
  content: "\e949";
}
.chat-chat:before {
  content: "\e91e";
}
.chat-pencil:before {
  content: "\e945";
}
.chat-menu:before {
  content: "\e946";
}
.chat-camera:before {
  content: "\e947";
}
.chat-members:before {
  content: "\e942";
}
.chat-files:before {
  content: "\e943";
}
.chat-chat-head:before {
  content: "\e944";
}
.chat-on-off:before {
  content: "\e941";
}
.chat-download:before {
  content: "\e93a";
}
.chat-dice:before {
  content: "\e936";
}
.chat-alert:before {
  content: "\e932";
}
.chat-clock:before {
  content: "\e933";
}
.chat-arrow-d:before {
  content: "\e934";
}
.chat-double-check:before {
  content: "\e935";
}
.chat-streaming:before {
  content: "\e931";
}
.chat-conference:before {
  content: "\e930";
}
.chat-screen:before {
  content: "\e918";
}
.chat-pin:before {
  content: "\e91a";
}
.chat-eye:before {
  content: "\e91b";
}
.chat-logout:before {
  content: "\e924";
}
.chat-speaker:before {
  content: "\e92a";
}
.chat-mic-mute:before {
  content: "\e900";
}
.chat-mute:before {
  content: "\e901";
}
.chat-cam-off:before {
  content: "\e902";
}
.chat-cam:before {
  content: "\e903";
}
.chat-arrow-tr:before {
  content: "\e904";
}
.chat-arrow-l:before {
  content: "\e905";
}
.chat-settings:before {
  content: "\e906";
}
.chat-mic:before {
  content: "\e907";
}
.chat-a-stop:before {
  content: "\e908";
}
.chat-a-record:before {
  content: "\e909";
}
.chat-a-pause:before {
  content: "\e90a";
}
.chat-a-play:before {
  content: "\e90b";
}
.chat-heart:before {
  content: "\e90c";
}
.chat-heart-o:before {
  content: "\e90d";
}
.chat-user:before {
  content: "\e90e";
}
.chat-cross:before {
  content: "\e90f";
}
.chat-forward:before {
  content: "\e910";
}
.chat-reply {
  transform: scaleX(-1);
  &:before {
    content: "\e910";
  }
}
.chat-send:before {
  content: "\e911";
}
.chat-smile:before {
  content: "\e912";
}
.chat-clip:before {
  content: "\e913";
}
.chat-dots:before {
  content: "\e914";
}
.chat-dots-v:before {
  content: "\e915";
}
.chat-info:before {
  content: "\e916";
}
.chat-check:before {
  content: "\e917";
}
.chat-v-chat:before {
  content: "\e919";
}
.chat-search:before {
  content: "\e91c";
}
.chat-archive:before {
  content: "\e91d";
}
.chat-add-group:before {
  content: "\e91f";
}
.chat-link:before {
  content: "\e920";
}
.chat-35:before {
  content: "\e921";
}
.chat-share:before {
  content: "\e922";
}
.chat-group:before {
  content: "\e923";
}
.chat-blend:before {
  content: "\e925";
}
.chat-remove:before {
  content: "\e926";
}
.chat-backwards:before {
  content: "\e927";
}
.chat-brush:before {
  content: "\e928";
}
.chat-fullscreen:before {
  content: "\e929";
}
.chat-plus:before {
  content: "\e92b";
}
.chat-horn:before {
  content: "\e92c";
}
.chat-group-o:before {
  content: "\e92d";
}
.chat-call-answer:before {
  content: "\e92e";
}
.chat-call-reject:before {
  content: "\e92f";
}
.chat-calendar:before {
  content: "\e937";
}
.chat-copy:before {
  content: "\e938";
}
.chat-volume_up:before {
  content: "\e939";
}
.chat-presentation:before {
  content: "\e93b";
}
.chat-chevron-down:before {
  content: "\e93c";
}
.chat-chevron-up:before {
  content: "\e93d";
}
.chat-chevron-right:before {
  content: "\e94b";
}
.chat-chevron-left:before {
  content: "\e94c";
}
.chat-upload:before {
  content: "\e93e";
}
.chat-eye-o:before {
  content: "\e93f";
}
.chat-eye-o-off:before {
  content: "\e940";
}
.chat-add-contact:before {
  content: "\e94e";
}
.chat-roll:before {
  content: "\e94f";
}
.chat-contacts:before {
  content: "\e950";
}
