
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.listBox {
  position: relative;
  min-height: 200px;
}

.lastElement {
  position: absolute;
  bottom: 0;
  left: 0;
}

.emptyBox {
  padding: 16px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.emptyImage {
  margin-bottom: 8px;
  width: 102px;
  height: 74px;
}

.emptyText {
  margin: 0;
  font-size: calc(var(--font-scale) * 14px);
  line-height: 1.5;
  color: $text-tertiary;
}
