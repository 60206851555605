
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.fileList {
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-flow: column;
  align-self: flex-start;
  list-style: none;
}

.fileItem {
  margin-bottom: 4px;
  background: $bg-second;
  border-radius: 8px;
  padding: 4px 32px 4px 4px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > i {
    margin-right: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.removeBtn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
