$phone-width: 420px;
$tablet-width: 821px;
$desktop-width: 1024px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'phone' {
    @media screen and (max-width: $tablet-width - 1) {
      @content
    }
  };
  @if $breakpoint == 'tablet' {
    @media screen and (min-width: $tablet-width) and (max-width: $desktop-width - 1) {
      @content
    }
  };
  @if $breakpoint == 'desktop' {
    @media screen and (min-width: $desktop-width) {
      @content
    }
  };
}

@mixin isTouch() {
  /* smartphones, touchscreens */
  @media (hover: none) and (pointer: coarse) {
    @content
  }
  /* stylus-based screens */
  @media (hover: none) and (pointer: fine) {
    @content
  }
  /* Nintendo Wii controller, Microsoft Kinect */
  @media (hover: hover) and (pointer: coarse) {
    @content
  }
}

@mixin isMouse() {
  /* mouse, touch pad */
  @media (hover: hover) and (pointer: fine) {
    @content
  }
}
