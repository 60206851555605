
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
$animation-time: 0.3s;
$photoSize: 400px;
$photoMarginTop: 6px;
$photoMarginBottom: 6px;
$fieldSize: 52px;
$containerPadding: 24px;

.section {
  $containerPadding: 24px;
  padding: 2px 0 10px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 2 * $containerPadding);
    height: 1px;
    bottom: 0;
    left: -$containerPadding;
    background: $bg-second;
  }
}

.modalTitle {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid $bg-primary;
  font-size: calc(var(--font-scale) * 21px);
  line-height: 1.2;
}

.fillImage {
  color: $main-color;
}

.animBox {
  display: flex;
  position: relative;
  min-height: 52px;
  max-height: 52px;
  height: $photoSize + $fieldSize + $photoMarginTop + $photoMarginBottom;
  transition: all $animation-time linear;

  &.active {
    cursor: pointer;
  }

  &.big {
    max-height: min($photoSize + $fieldSize + $photoMarginTop + $photoMarginBottom,
    calc(100vw + $fieldSize + $photoMarginTop + $photoMarginBottom - 2 * $containerPadding));
  }
}

.photoEditor.photoEditor {
  position: absolute;
  top: $photoMarginTop;
  left: 0;
  width: 100%;
  max-height: min($photoSize + $fieldSize + $photoMarginTop + $photoMarginBottom,
  calc(100vw - 2 * $containerPadding));;
}

.infoGroup {
  padding-left: 52px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
}

.infoDesc {
  font-size: calc(var(--font-scale) * 12px);
  color: $text-tertiary;
  line-height: 1;
}

.avatar.avatar {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  height: min($photoSize, 100vw - 2 * $containerPadding);
  width: min($photoSize, 100vw - 2 * $containerPadding);
  max-height: 48px;
  max-width: 48px;
  transform: translate(0, -50%);
  transition: all $animation-time linear;
  cursor: pointer;

  &.big {
    max-height: min($photoSize, 100vw - 2 * $containerPadding);
    max-width: min($photoSize, 100vw - 2 * $containerPadding);
    border-radius: 8px;
    top: $photoMarginTop;
    left: 15.5px;
    font-size: 150px;
    transform: translate(0, 0);
    @include respond-to(phone) {
      left: max((100vw - 2 * $containerPadding) / 2 - $photoSize / 2, 0px);
    }
  }
}

.tabPanel {
  margin: 24px 0;
}

.field {
  padding-left: 20px;
}

.fieldText {
  display: flex;
  margin: 0;
  padding-right: 12px;
  width: 100%;
  font-weight: 700;
}

.groupTypeText {
  margin-left: auto;
  color: $text-tertiary;
  font-weight: normal;
}

.groupNameText {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settinsBtn {
  margin-left: auto;
}

.inputNameEdit {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  min-height: 52px !important;

  &.compressed {
    & input {
      margin-left: 13px;
      padding-left: 3px;
    }
  }
}

.menuBox {
  margin-left: auto;
  display: flex;
  align-self: center;
  .divider {
    display: flex;
    margin: 0 12px;
    svg {
      display: flex;
      align-self: center;
    }
  }
}

.insideModal {
  background: transparent;
}

.menuHead {
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
    margin-right: 12px;
  }
}

.channelDescription {
  position: relative;
  font-size: calc(var(--font-scale) * 12px);
  color: $text-tertiary;
  line-height: 133%;
  font-weight: 400;
  padding: 12px 0;
  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 2 * $containerPadding);
    height: 1px;
    bottom: 0;
    left: -$containerPadding;
    background: $bg-second;
  }
}
