
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.messageWrapper {
  display: flex;
  overflow: hidden;
  max-width: 100%;
}

.fileImgWrapper {
  position: relative;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background: $main-color;
  border-radius: 50%;
  cursor: pointer;
}

.messageInfo {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.name,
.size {
  margin: 0;
  color: $text-secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.size {
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  margin-right: 8px;
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bottom {
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
    align-self: flex-end;
    .time {
      font-size: calc(var(--font-scale) * 10px);
      line-height: 1;
      align-self: flex-end;
    }
    i {
      margin-left: 4px;
      color: $main-color;
      .chat-check {
        color: #252d3c;
      }
      &.chatPin {
        font-size: calc(var(--font-scale) * 12px);
        margin: 0 6px 0 0;
        color: black;
      }
    }
  }
}
