
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.wrapper {
  position: relative;
  display: flex;
  min-height: 190px;
}

.selectAllBtn {
  padding: 4px;
  position: absolute;
  top: 0;
  right: 10px;
  color: $main-color;
  z-index: 1;
}

.sizerBox {
  display: flex;
  flex: 1;
}

.sizerWrapper {
  //min-height: 300px;
  flex: 1 1 auto;
}

.emptyBox {
  padding: 20px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.emptyImg {
  width: 138px;
  height: 93px;
}

.emptyTxt {
  font-size: calc(var(--font-scale) * 14px);
  line-height: 1.5;
  color: $text-tertiary;
}
