
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.time {
  position: absolute;
  font-size: calc(var(--font-scale) * 10px);
  line-height: 1;
  align-items: flex-end;
  right: 0;
  bottom: 0;

  i {
    margin-left: 4px;
    color: $main-color;
    &.sent {
      color: $message-text;
    }
    &.chatPin {
      font-size: calc(var(--font-scale) * 12px);
      margin: 0 6px 0 0;
      color: black;
    }
  }
}

.timeWidth {
  display: inline-block;
  width: calc(var(--font-scale) * 85px);
}

.text {
  position: relative;
  margin: 0;
  font-size: calc(var(--font-scale) * 16px);
  line-height: 150%;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  a {
    -webkit-touch-callout: none
  }
}

.link {
  color: $link-color;
  text-decoration: none;
}
