
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.notifyBox {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  padding: 12px;
  background-color: $text-primary;
  box-shadow: 0 4px 20px rgba(30, 32, 41, 0.15);
  border-radius: 16px;
  z-index: 10000;
  margin: 1rem;
  align-items: center;
}

.text {
  color: #fff;
  font-size: calc(var(--font-scale) * 16px);
}

.icon {
  margin-right: 8px;
}

.btn {
  color: $text-placeholder;
}
