
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.list {
  display: flex;
  flex-flow: row wrap;

  > * {
    margin-right: 8px;
    margin-top: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}
