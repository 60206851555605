
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.header {
  padding: 9px 16px 9px 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.notActive {
    cursor: default;
  }
}

.avatar {
  margin: 0 16px;
}

.content {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: space-evenly;
  height: 100%;
  overflow: hidden;
}

.chatNameBox {
  display: flex;
  align-items: center;
}

.userName {
  margin: 0;
  max-width: 100%;
  font-size: calc(var(--font-scale) * 21px);
  line-height: 1.2;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  + i {
    margin-left: 8px;
  }
}

.backButton {
  height: 100%;
  font-size: calc(var(--font-scale) * 16px);
  padding-left: 16px;
  padding-right: 16px;
}

.infoIcon {
  margin-left: 16px;
  font-size: calc(var(--font-scale) * 22px);
}

.members {
  max-width: 100%;
  margin: 0;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  color: $text-tertiary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.joinBtn {
  padding: 8px 20px;
  font-weight: 700;
  display: flex;
  align-items: center;

  > * {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.joinIcon {
  font-size: 18px;
}
