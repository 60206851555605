
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.callMsgBox {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.callImg {
  margin-right: 16px;
  width: 20px;
  height: 20px;
  fill: $main-color;

  .missed & {
    fill: $warning-color;
  }
}

.callText {
  display: flex;
  align-items: center;

  &::before {
    margin-right: 8px;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-position: center;
    background-size: contain;
    background-image: url('/images/icons/outgoing-arrow.svg');
    transform: rotate(-45deg);
  }

  &.incoming::before {
    transform: rotate(135deg);
  }

  &.missed::before {
    background-image: url('/images/icons/incoming-arrow.svg');
    transform: rotate(135deg);
  }

  &.incoming.missed::before {
    transform: rotate(-45deg);
  }
}

.duration {
  margin-left: 16px;
  font-size: calc(var(--font-scale) * 14px);
}

.time {
  position: absolute;
  bottom: -2px;
  right: 0;
  font-size: calc(var(--font-scale) * 10px);
}

