
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
}

.list {
  padding: 0;
  display: flex;
  flex-flow: column;
  list-style: none;
}

.ticketCard {
  margin-bottom: 16px;
}

.preloader {
  width: 40px;
  height: 40px;
  align-self: center;
}
