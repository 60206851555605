
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.imageWrapper {
  position: relative;
  min-height: 128px;
  max-height: 400px;
  max-width: 400px;
  min-width: 150px;
  border-radius: 16px 16px 0 16px;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;

  @include isTouch() {
    -webkit-touch-callout: default !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    * {
      -webkit-touch-callout: default !important;
      -webkit-user-select: none !important;
      user-select: none !important;
    }
    img {
      pointer-events: none;
    }
  }
  &.incoming {
    border-radius: 16px 16px 16px 0;
  }

  &.notActive {
    cursor: default;
  }

  .image{
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: -1;

    &.sending {
      filter: blur(3px) grayscale(1) brightness(1.7);
    }
  }
  .text {
    background-color: rgba(0,0,0,.5);
    bottom: 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    font-size: calc(var(--font-scale) * 12px);
    line-height: 133%;
    padding: 4px 16px 4px 8px;
    position: absolute;
    width: 100%;
    .right {
      display: flex;
      white-space: nowrap;
      .time {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 4px;
        .chatPin {
          font-size: calc(var(--font-scale) * 12px);
          margin: 0 6px 0 0;
          color: white;
        }
      }
    }
    .nick {
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .forwardedFrom {
    margin: 0;
    padding: 4px 16px;
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: calc(var(--font-scale) * 12px);
    line-height: 1.33;
    font-weight: 700;
    background: $main-color;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .replyWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 16px;
    background: #fff;
  }
}
