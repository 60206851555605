
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  $stub-width: 240px;
  $stub-height: 120px;
  $preloader-width: 20px;
  $preloader-height: $preloader-width;

  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  max-width: 100%;
  max-height: 70vh;

  .replyBox {
    padding: 5px 16px;
    background: #fff;
    border-radius: 16px 16px 0 0;
    border: 1px solid $main-color;
    top: 0;
    right: 0;
    z-index: 2;
  }

  .video {
    max-width: 100%;
    max-height: 70vh;
    border-radius: 16px;

    -webkit-touch-callout: none;

    &.notLoaded {
      position: absolute;
      width: $stub-width;
      height: $stub-height;
      opacity: 0;
    }
  }

  &.withAvatar {
    max-width: calc(100% - 56px);
  }

  &.withReply {
    video {
      max-height: calc(70vh - 64px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .stubBox {
    position: relative;
  }

  .stub {
    display: block;
    width: $stub-width;
    height: $stub-height;
    top: 0;
    right: 0;
    border-radius: 24px;
    background: linear-gradient(to bottom, #323232, #000000);
  }

  .preloader {
    width: $preloader-width;
    height: $preloader-height;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  &.incoming {
    align-items: flex-start;

    .replyBox {
      left: 0;
      right: auto;
    }

    .stub {
      left: 0;
      right: auto;
    }
  }
}
