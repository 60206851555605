
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
  box-shadow: 0 0 16px 4px rgba(#000, .2);
  border-radius: 16px;
  overflow: hidden;

  .fullscreen {
    box-shadow: none;
    border-radius: 0;
  }

  &.prePopup {
    background: rgba(#000, .7);
    border-radius: 0;
  }
}

.frame {
  background: #fff;
  border: none;
  width: 100%;
  height: 100%;

  &.medium {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: 380px;
    height: 400px;
    border-radius: 16px;
    box-shadow: 0 0 16px 4px rgba(#000, 0.2);
  }
}

.disableSelect {
  -webkit-touch-callout: default !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  overscroll-behavior-y: none;
  body {
    -webkit-touch-callout: default !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    overscroll-behavior-y: none;
    * {
      -webkit-touch-callout: default !important;
      -webkit-user-select: none !important;
      user-select: none !important;
      overscroll-behavior-y: none;
      pointer-events: none;
    }
  }

}

.buttons {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: move;
  pointer-events: auto;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.button {
  font-size: 20px;

  &.resize {
    position: absolute;
  }

  &.move {
    top: 10px;
    left: 10px;
    cursor: move;
  }

  &.resize {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: nw-resize;

    &::after,
    &::before {
      content: "";
      position: absolute;
      height: 2px;
      background: #4c4c4c;
      transform: rotate(-45deg);
    }

    &::after {
      bottom: 3px;
      right: 3px;
      width: 4px;
    }

    &::before {
      bottom: 7px;
      right: 4px;
      width: 12px;
    }
  }
}
