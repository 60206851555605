
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.logoContainer {
  padding: 15px 16px;
  display: flex;
}

.logo {
  width: 158px;
  height: 36px;
  fill: $main-color;
}
