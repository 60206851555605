
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.teamIcon {
  padding: 2px 4px;
  font-size: calc(var(--font-scale) * 8px);
  background: $connect-color;
  border-radius: 2px;
  color: #fff;
}
