
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  min-width: 100px;
}

.img {
  width: 50px;
  height: 50px;
  animation: linear circlePreloaderAnim 0.9s infinite;
}

@keyframes circlePreloaderAnim {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
