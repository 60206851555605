
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.pin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 8px 16px;
  border-bottom: 1px solid #EBF0F4;
  .body {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 4px 16px 4px 0;
    overflow: hidden;
    cursor: pointer;
    .rectangle {
      width: 2px;
      height: auto;
      border-radius: 4px;
      background: #3886EF;
      margin-right: 8px;
    }
    div {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .title {
        color: #2C3653;
        font-size: calc(var(--font-scale) * 12px);
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
      }
      .text {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #505D77;
        font-size: calc(var(--font-scale) * 14px);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        .chatClip {
          margin-right: 4px;
          font-size: calc(var(--font-scale) * 16px);
          color: #505D77;
          transform: rotate(90deg);
        }
      }
    }
  }
  .chatCross {
    cursor: pointer;
  }
}
