
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.replyMessage {
  padding: 8px 0 0;
  display: flex;
  align-items: center;
  position: relative;
}

.closeBtn {
  position: relative;
  align-self: flex-start;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 12px;
    position: absolute;
    top: calc(50% - 1px);
    background: $text-placeholder;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @include isMouse() {
    &:hover {
      background: rgba(#ababab, .2);
    }
  }

  &:active {
    background: rgba(#ababab, .4);
  }
}

.replyArrow {
  margin: 0 12px 0 8px;
  color: $main-color;
}

.content {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  flex: 1;
  overflow: hidden;
}

.userName {
  max-width: calc(100% - 15px);
  margin: 0;
  color: $main-color;
  font-size: calc(var(--font-scale) * 12px);
  font-weight: 700;
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textMessage,
.typeMessage {
  margin: 0;
  max-width: calc(100% - 15px);
  font-size: calc(var(--font-scale) * 16px);
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textMessage {
  color: #000;
}

.typeMessage {
  color: $text-tertiary;
  text-transform: capitalize;
}

.image {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 4px;
  object-fit: cover;
}

.fileImage {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $main-color;
}

