$buttonHoverColor: #0193E6;
$buttonDisabledColor: #9CACC9;
$buttonActiveBorder: #A5DCFA;

* {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

::-webkit-scrollbar {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
  background-color: transparent;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  width: 6px;
  height: 50px;
}

.buttonIcon {
  border: none;
  outline: none;
  cursor: pointer;

  &.disable {
    cursor: default;
  }
}

.d-none {
  display: none !important;
}

.button {
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 16px;
  border: 2px solid transparent;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;

  &.disabled {
    cursor: default;
  }
}

.mainButton {
  background-color: $main-color;
  color: #fff;
  @include isMouse(){
    &:hover {
      background-color: $buttonHoverColor;
    }
  }
  &:active:not(.disabled) {
    border-color: $buttonActiveBorder;
  }

  &.disabled {
    background: $text-placeholder;
  }
}

.secondaryButton {
  background: transparent;
  border-color: $main-color;
  color: $main-color;

  @include isMouse(){
    &:hover {
      text-decoration: underline;
    }
  }
}

.darkButton {
  background: $message-text;
  color: #fff;

  @include isMouse(){
    &:hover {
      background: $text-primary;
    }
  }

  &:active {
    background: #142538;
  }
}

.attentionButton {
  background-color: $attention-color;
  color: #fff;

  @include isMouse(){
    &:hover {
      background-color: lighten($attention-color, 10%);
    }
  }

  &:active {
    background-color: darken($attention-color, 10%);
  }

  &.disabled {
    background: $text-placeholder;
  }
}

.linkButton {
  padding: 12px 24px;
  background: transparent;
  color: $text-primary;
  text-decoration: none;

  @include isMouse(){
    &:hover {
      text-decoration: underline;
    }
  }

  &.disabled {
    color: $text-tertiary;
    cursor: default;
    text-decoration: none;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  opacity: 0;
  input,
  textarea {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    caret-color: transparent !important;
    opacity: 0;
  }
}

@for $i from 1 through length($theme-users) {
  .userColor#{$i} {
    background-color: nth($theme-users, $i);
    color: lighten(nth($theme-users, $i), 35)
  }
}
