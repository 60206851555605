
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
  display: flex;
  flex-flow: column;
  min-height: 200px;
}

.field {
  margin-bottom: 16px;
}

.fileBtn {
  align-self: flex-start;
  margin-left: -24px;
  margin-bottom: 16px;
}

.fileList {
  margin-bottom: 8px;
}

.preloaderBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader {
  width: 40px;
  height: 40px;
}
