
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  max-height: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.stickerTabs {
  margin: 0;
  padding: 0 0 4px;
  display: flex;
  max-width: 100%;
  overflow: auto;
  list-style: none;
  flex: 0 0 auto;
}

.packTab {
  padding: 0 8px;
  border-bottom: 3px solid transparent;
  cursor: pointer;

  &.active {
    border-bottom-color: $main-color;
    cursor: default;
  }
}

.packIcon {
  width: 40px;
  height: 40px;
}

.stickers {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: wrap;
  list-style: none;
  overflow: auto;
}

.sticker {
  margin: 8px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
