
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.navTabs {
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 6px 0 12px;
  list-style: none;
  cursor: pointer;
}

.navItem {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  color: $text-tertiary;

  @include isMouse() {
    &:hover {
      .text, .icon {
        color: $text-primary
      }
      svg {
        fill: $text-primary;
        color: $text-primary;
      }
    }
  }
}

.icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.adxIcon {
  margin-bottom: 8px;
}

.text {
  margin: 0;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  color: $text-tertiary;
}

.active {
  position: relative;
  cursor: default;

  .text, .icon {
    color: $text-primary
  }
  svg {
    fill: $text-primary;
    color: $text-primary;
  }

  &::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 42px;
    background-color: $main-color;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.counter {
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  position: absolute;
  top: 0;
  left: calc(50% + 20px);
  transform: translateX(-50%);
  background: $main-color;
  border-radius: 10px;
  color: #fff;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1;
}

.hintText {
  margin: 0;
  max-width: min(90vw, 300px);
  white-space: pre-wrap;
}
