
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.wrapper {
  width: calc(100% - 32px);
  max-width: 848px;
  height: min(90%, 605px);
  max-height: 605px;
  @include respond-to(phone) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.box {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1;
}

.btn {
  min-width: 64px;
  min-height: 64px;
  color: #fff;
  background-color: $main-color;
  font-size: 24px;
  border-radius: 50%;
}

.accessBtn {
  background-color: $attention-color;
}

.cancelBtn {
  background-color: $text-tertiary;

  &.incoming {
    background-color: $warning-color;
  }
}

.closeBtn {
  position: absolute;
  right: 24px;
  top: 16px;
  font-size: calc(var(--font-scale) * 21px);
}

.btnBox {
  display: flex;
  padding: 10px 0 0;
  margin: auto 0 40px;

  > *:nth-child(n+2) {
    margin-left: 16px;
  }
}

.card {
  margin: auto 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.name {
  margin: 32px 0 16px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
}

