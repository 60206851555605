
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  color: $text-secondary;
  font-size: calc(var(--font-scale) * 12px);
  font-weight: 500;

  .bold {
    font-weight: 700;
  }
}
