
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
  padding: 42px 16px 16px;
  background: $bg-second;
  border-radius: 8px;
}

.status {
  position: absolute;
  top: 8px;
  left: 8px;
}

.title {
  margin: 0 0 24px;
  font-size: calc(var(--font-scale) * 18px);
  font-weight: 700;
}

.options {
  display: flex;
  flex-flow: column;
}

.option {
  margin: 0;
  font-size: calc(var(--font-scale) * 14px);
}

.optionName {
  font-weight: 700;
}
