
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.textarea {
  padding: 12px;
  resize: none;
  border-radius: 16px;
  border: 1px solid $input-border;
  outline: none;
  font-size: 100%;
  line-height: 1.15;
  font-family: inherit;
}
