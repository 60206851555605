
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.label {
  position: relative;
  background: $bg-second;
  border-radius: 8px;
  display: flex;

  &.light {
    background: #fff;
    border: 1px solid $input-border;

    @include isMouse() {
      &:hover {
        border-color: $main-color;
      }
    }

    &:focus-within
    {
      border-color: $text-secondary;
    }
  }

  &.big {
    border-radius: 16px;
  }
}

.input {
  padding: 10px 38px 9px 34px;
  background: transparent;
  font-size: calc(var(--font-scale) * 16px);
  border: none;
  outline: none;
  flex: 1;
  &.big {
    padding: 11px 32px;
  }
  &::placeholder {
    color: $text-placeholder;
  }
}

.icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: $text-tertiary;
  font-size: calc(var(--font-scale) * 18px);
}

.closeBtn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: calc(var(--font-scale) * 18px);
}
