
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.viewer {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(#000, .7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.buttonBox {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transition: opacity 1s;

  > button:nth-child(n+2) {
    margin-left: 16px;
  }

  &.hide {
    opacity: 0;
  }

  @include respond-to(phone) {
    bottom: 25px;
    right: 25px;
  }
}

.headerButtons {
  top: 42px;
  right: 42px;
  bottom: auto;
  @include respond-to(phone) {
    top: 25px;
    right: 25px;
  }
}

.footerButtons {
  bottom: 42px;
  right: 42px;
  @include respond-to(phone) {
    bottom: 25px;
    right: auto;
  }
}

.invisible {
  opacity: 0;
}

.action {
  display: block;
  color: #fff;
  font-size: calc(var(--font-scale) * 22px);
}

.btn.btn {
  padding: 8px;

  &:active > .action,
  &:active > svg
  {
    opacity: .3;
  }
}

.btnDivider {
  width: 1px;
  background: rgba(#fff, .1);
  margin: 8px 0 8px 16px;
  align-self: stretch;
}
