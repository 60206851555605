
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.text {
  position: relative;
  margin: 0;
  font-size: calc(var(--font-scale) * 16px);
  line-height: 150%;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  a {
    -webkit-touch-callout: none
  }
}

.time {
  position: absolute;
  font-size: calc(var(--font-scale) * 10px);
  line-height: 1;
  align-items: flex-end;
  right: 0;
  bottom: 0;
}
