
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    margin-bottom: 32px;
  }
  .button {
    width: 100%;
  }
}
