
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
  display: flex;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: $main-color;
  color: #fff;
  font-size: 14px;

  &.closable {
    padding-right: 26px;
  }
}

.btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #fff;
}
