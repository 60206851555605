
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.buttons {
  margin-top: 8px;
  display: flex;
  > button:nth-child(n+2) {
    margin-left: 16px;
  }
}

.search {
  margin-bottom: 8px;
}
