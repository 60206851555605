
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.divider {
  width: 100%;
  color: $text-secondary;
  font-size: calc(var(--font-scale) * 12px);
  font-weight: 500;
  margin-bottom: -4px;
  padding: 8px;
  text-align: center;
}
