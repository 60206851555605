
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
html, body {
  height: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: no-repeat center / cover;
  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    .top {
      display: flex;
      justify-content: space-between;
      height: 64px;
      .left {
        display: flex;
        align-items: flex-end;
        .logo {
          padding: 0 32px;
          svg {
            fill: #fff;
          }
        }
      }
      .right {
        background-color: #9CACC9;
      }
    }
    .body {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 450px;
      @include respond-to(phone) {
        width: 100%;
      }
      h4 {
        margin: 0 0 24px 0;
        color: $text-primary;
        font-size: calc(var(--font-scale) * 24px);
        font-weight: 700;
        line-height: 1.2;
      }
      .row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        .captcha {
          max-width: calc(100% - 86px);
        }
        .errorText {
          color: $warning-color;
          font-size: calc(var(--font-scale) * 12px);
          line-height: 133%;
          text-align: left;
          margin-top: 4px;
        }
        .rowCaptcha {
          display: flex;
          flex-direction: row;
          max-width: 100%;
        }
        .labelText {
          font-size: calc(var(--font-scale) * 14px);
          line-height: 150%;
          margin-bottom: 4px;
          text-align: left;
        }
        .error {
          border-color: $warning-color;
        }
        label {
          position: relative;
          min-width: 70px;
          i {
            color: #9CACC9;
            font-size: calc(var(--font-scale) * 22px);
            line-height: 48px;
            position: absolute;
            right: 17px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
      }
      .logInBtn {
        width: 100%;
        margin-top: 16px;
      }
      .forgotBtn {
        width: 100%;
        padding-bottom: 0;
      }
    }
    .preloader {
      width: 50px;
      height: 50px;
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }
}
