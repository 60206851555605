
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
}

.messages {
  margin: 0;
  padding: 8px 16px;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
  list-style: none;
}

.scrollBtn {
  width: 48px;
  height: 48px;
  background: #f1f2f6;
  border-radius: 50%;
  position: absolute;
  bottom: 25px;
  right: 25px;
  box-shadow:  4px 5px 12px -3px rgba(#000, .4);
}

.highlightMessage {
  z-index: 1;
}

.highlight {
  $timeout: 5s;
  position: absolute;
  border-radius: 4px;
  animation: messageHighlight $timeout;
  z-index: -1;
}

@keyframes messageHighlight {
  0% {
    background: rgba($main-color, .5);
  }
  100% {
    background: rgba($main-color, 0);
  }
}
