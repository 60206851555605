
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.container {
  position: relative;
  min-width: 200px;
  min-height: 200px;
}

.loader {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
