
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.main {
  color: $main-color;
  padding: 8px 16px;
  width: 85px;
  position: absolute;
  top: 24px;
  right: 32px;
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
  line-height: 1.5;
  display: flex;
  flex-shrink: 0;
}

.chevron {
  margin-left: auto;
  font-size: calc(var(--font-scale) * 20px);
  line-height: 1;
  align-self: center;
}

.dropdown {
  position: absolute;
  border-radius: 16px;
  min-width: 100%;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  background-color: #fff;
  &.shown {
    opacity: 1;
    pointer-events: all;
    @include respond-to(phone) {
      z-index: 10;
    }
  }
  > a {
    color: $main-color;
    word-break: keep-all;
    line-height: 2;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      font-weight: 500;
    }
  }
}
