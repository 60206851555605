
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.youchat {
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-flow: column;
  .main {
    display: grid;
    grid-template-areas:
    "chatPanel messagesPanel";
    grid-template-columns: 390px 1fr;
    grid-template-rows: 1fr;
    flex: 1 0 auto;
    max-width: 1024px;
    width: calc(100% - 32px);
    height: calc(100vh - 32px);
    margin: 16px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;

    &.mobile {
      margin: 0;
      height: 100vh;
      width: 100%;
      border-radius: 0;
      display: flex;
      flex-flow: column;
    }
  }

  .chatPanel {
    grid-area: chatPanel;
    display: grid;
    grid-template-areas:
          "logo"
          "chatList"
          "navTabs";
    grid-template-columns: 1fr;
    grid-template-rows: 68px 1fr minmax(68px, auto);
    flex: 1;
  }

  .messagesPanel {
    position: relative;
    grid-area: messagesPanel;
    display: grid;
    grid-template-areas:
      "chatHeader"
      "messages";
    grid-template-columns: 1fr;
    grid-template-rows: 68px 1fr;
    flex: 1;
  }

  .tabModal {
    padding-left: 30px;
    right: 0;
    height: 100%;
    display: flex;
  }

  .dropZone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    position: absolute;
    top: 16px;
    left: 16px;
    height: calc(100% - 32px);
    width: calc(100% - 32px);
    border: 2px dashed $main-color;
    background: #fff;
    z-index: 1;

    &.warning {
      border-color: $warning-color;
    }
  }

  .dropText {
    font-size: calc(var(--font-scale) * 24px);
  }

  .dropImage {
    font-size: calc(var(--font-scale) * 40px);
    color: $main-color;
  }

  .logo {
    grid-area: logo;
    border-bottom: $chat-area-border;
    border-right: $chat-area-border;
  }

  .chatList {
    grid-area: chatList;
    display: flex;
    flex-flow: column;
    flex: 1;
  }

  .chatListBorder {
    position: relative;
    border-right: $chat-area-border;
  }

  .header {
    grid-area: chatHeader;
    border-bottom: $chat-area-border;
    overflow: hidden;
  }

  .messages {
    grid-area: messages;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    .wrapper {
      display: flex;
      flex-flow: column;
      overflow: hidden;
    }
  }

  .navTabs {
    grid-area: navTabs;
    border-right: $chat-area-border;
    border-top: $chat-area-border;
  }

  .inputBox {
    border-top: 2px solid $bg-primary;
    min-height: 68px;
  }
}

.connectPanel {
  min-height: 68px;
}

.spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  > svg {
    width: 100%;
    height: 100%;
    fill: $main-color;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: inset 0 0 16px 4px $main-color;
    animation: pulse-live 1s infinite linear;
  }
}

@keyframes pulse-live {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.conferenceFrame.conferenceFrame {
  position: fixed;
  width: 100%;
  height: 100%;
}
