
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.panel {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-top: $chat-area-border;
  font-size: calc(var(--font-scale) * 16px);
  font-weight: 700;
  line-height: 1.5;
}

.connectIcon {
  margin-right: 8px;
}

.text {
  margin: 0;
}
