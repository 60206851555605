
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.inputBox {
  padding: 0 18px 12px;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  @include isTouch() {
    -webkit-user-select: none !important;
  }

  & * {
    @include isTouch() {
      -webkit-user-select: none !important;
    }
  }

  &.disabled {
    padding: 12px;
  }
}

.content {
  padding-top: 12px;
  display: flex;
  align-items: center;
}

.inputField {
  flex-grow: 1;
  box-shadow: none;
  word-break: break-word;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: calc(var(--font-scale) * 16px);

  -webkit-user-select: auto !important;
  -webkit-touch-callout: default !important;

  white-space: pre-wrap !important;
  flex-flow: row nowrap;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: center;
  resize: none;
  transition: none;
  border: none;
  outline: none;
  &::placeholder {
    color: $text-placeholder;
  }
}

.iconButton {
  min-width: 40px;
  min-height: 40px;
  font-size: calc(var(--font-scale) * 22px);
  color: $text-tertiary;
}

.sendButton {
  color: $main-color;
}

.recordText {
  margin: 0 0 0 10px;
  font-size: calc(var(--font-scale) * 14px);
  line-height: 1.5;
  color: $text-secondary;
}

.stubText {
  margin: auto;
  color: $text-tertiary;
}

.micBtn {
  margin-left: auto;
  border-radius: 50%;
  &.active {
    color: #fff;
    background: $main-color;
  }
}

.recordMark {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $warning-color;
  animation: warning 1s infinite;
}

@keyframes warning {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  51% {
    opacity: 0.3;
  }
  75% {
    opacity: .3;
  }
  100% {
    opacity: 1;
  }
}

