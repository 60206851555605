
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.chatItem {
  display: grid;
  grid-template-areas:
    "avatar name time"
    "avatar message time";
  grid-template-columns: 48px 1fr auto;
  height: 80px;
  padding: 8px 16px;
  gap: 0 16px;
  border-left: 2px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: $bg-second;
  }

  &.active {
    background-color: $bg-second;
    border-left-color: $main-color;
  }

  @include isTouch() {
    -webkit-user-select: none !important;
  }
}

.addInfo {
  display: flex;
  flex-flow: column;
}

.addInfoIcons {
  margin-top: auto;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.avatar {
  grid-area: avatar;
  align-self: center;
}

.titleBlock {
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;

  .name {
    grid-area: name;
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    > svg {
      height: 14px;
      width: 14px;
      flex: 0 0 auto;
      color: $main-color;
    }

    > * {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .textName {
    display: inline-block;
    max-width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .time {
    align-self: flex-start;
    color: $text-secondary;
    flex-shrink: 0;
    font-size: calc(var(--font-scale) * 12px);
    margin-left: 8px;
  }
}

.time {
  margin: 0;
  grid-area: time;
  font-size: calc(var(--font-scale) * 12px);
  text-align: right;
}

.lastMessage,
.draft {
  width: 100%;
  margin: 0;
  grid-area: message;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $text-secondary;
  font-size: calc(var(--font-scale) * 14px);
  font-weight: normal;
}

.draft {
  color: $main-color;
}

.category {
  padding: 8px 16px;
  color: $text-secondary;
  font-size: calc(var(--font-scale) * 14px);
  font-weight: 700;
  line-height: 1.5;
}

.avatarBlock {
  position: relative;
}

.online {
  background-color: $attention-color;
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 4px;
  height: 16px;
  position: absolute;
  right: -2px;
  width: 16px;
}

.counter {
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  background: $main-color;
  border-radius: 10px;
  color: #fff;
  font-size: calc(var(--font-scale) * 14px);
  line-height: 1;

  &:last-child {
    margin-right: 34px;
  }

  &::before {
    border-radius: 10px;
    box-shadow: inset 0 0 16px 4px $main-color;
  }
}

.groupCallIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $attention-color;
  color: #fff;
  font-size: calc(var(--font-scale) * 14px);

  &::before {
    border-radius: 20px;
    box-shadow: inset 0 0 16px 4px $attention-color;
  }
}

.counter,
.groupCallIcon {
  position: relative;
  z-index: 1;

  > span {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: pulse-live 1s infinite linear;
  }
}

@keyframes pulse-live {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}

.sponsorIcon {
  width: 20px;
  height: 20px;
}

.menuButton {
  font-size: calc(var(--font-scale) * 18px);
}

.groupCallIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $attention-color;
  color: #fff;
  font-size: calc(var(--font-scale) * 14px);
}
