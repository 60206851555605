
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.participant {
  display: flex;
  align-items: center;
}

.userName {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    padding-right: 46px;
  }

  + * {
    margin-left: auto;
  }
}

.memberType {
  margin-left: auto;
  margin-right: 10px;
  color: $text-tertiary;
  font-size: calc(var(--font-scale) * 14px);

  &:last-child {
    margin-right: 54px;
  }
}

.accessIcon {
  margin-right: 8px;
  color: $main-color;
}

.menuBtn {
  margin-right: 16px;
  color: $text-tertiary;

  &.muted {
    color: $warning-color;
  }
}

.teamIcon {
  margin-right: 4px;
}

.openChatBtn {
  margin-right: 16px;
  font-size: 22px;
  color: $main-color;
}
