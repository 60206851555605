
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
}

.textarea {
  width: 100%;
  margin-bottom: 8px;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.icon {
  font-size: calc(var(--font-scale) * 24px);
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    color: $main-color;
  }
}
