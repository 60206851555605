
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  display: flex;
  flex-flow: column;
  @include isTouch() {
    -webkit-touch-callout: default !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    * {
      -webkit-touch-callout: default !important;
      -webkit-user-select: none !important;
      user-select: none !important;
    }
    img {
      pointer-events: none;
    }
  }
}

.img {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.bottom {
  display: flex;
  align-self: flex-end;
  padding-right: 16px;
  .time {
    display: flex;
    align-self: center;
    font-size: calc(var(--font-scale) * 10px);
    line-height: 1.33;
    color: $text-primary;
    margin-left: auto;
    i.chatPin {
      font-size: calc(var(--font-scale) * 12px);;
      margin: 0 6px 0 0;
      color: black;
    }
  }
  i {
    margin-left: 4px;
    color: $main-color;
    align-self: center;
    .chat-check {
      color: #252d3c;
    }
  }
}

.replyBox {
  max-width: 150px;
  padding: 5px 10px;
  border-radius: 10px 10px 0 10px;
  border: 1px solid $main-color;

  &.incoming {
    border-color: $input-border;
    border-radius: 10px 10px 10px 0;
  }
}
