
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  max-height: 100%;
}

.container {
  $time-animation: .4s;
  max-width: 100vw;
  position: relative;
  display: flex;
  background: #fff;
  flex-flow: column;
  box-shadow: -8px 0 40px 0 rgba(0, 0, 0, 0.10);
  overflow: auto;
  left: 100%;

  &.show {
    left: 0;
    animation: tab-show-animation $time-animation;
  }

  &.hide {
    animation: tab-show-animation-reverse $time-animation;
  }
}

@keyframes tab-show-animation {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@keyframes tab-show-animation-reverse {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
