/* noto-sans-tc-100 - chinese-traditional */
//@font-face {
//  font-family: 'Noto Sans TC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 100;
//  src: url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-100.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans TC Thin'), local('NotoSansTC-Thin'),
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-100.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-100.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-tc-300 - chinese-traditional */
//@font-face {
//  font-family: 'Noto Sans TC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 300;
//  src: url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-300.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans TC Light'), local('NotoSansTC-Light'),
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-300.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-300.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-tc-regular - chinese-traditional */
@font-face {
  font-family: 'Noto Sans TC';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('./noto-sans-tc/noto-sans-tc-v9-chinese-traditional-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans TC Regular'), local('NotoSansTC-Regular'),
  url('./noto-sans-tc/noto-sans-tc-v9-chinese-traditional-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./noto-sans-tc/noto-sans-tc-v9-chinese-traditional-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./noto-sans-tc/noto-sans-tc-v9-chinese-traditional-regular.woff') format('woff'); /* Modern Browsers */
}
/* noto-sans-tc-500 - chinese-traditional */
//@font-face {
//  font-family: 'Noto Sans TC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 500;
//  src: url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-500.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans TC Medium'), local('NotoSansTC-Medium'),
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-500.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-500.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-tc-700 - chinese-traditional */
//@font-face {
//  font-family: 'Noto Sans TC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 700;
//  src: url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-700.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans TC Bold'), local('NotoSansTC-Bold'),
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-700.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-700.woff') format('woff'); /* Modern Browsers */
//}
/* noto-sans-tc-900 - chinese-traditional */
//@font-face {
//  font-family: 'Noto Sans TC';
//  font-display: fallback;
//  font-style: normal;
//  font-weight: 900;
//  src: url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-900.eot'); /* IE9 Compat Modes */
//  src: local('Noto Sans TC Black'), local('NotoSansTC-Black'),
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-900.woff2') format('woff2'), /* Super Modern Browsers */
//  url('/assets/fonts/noto-sans-tc/noto-sans-tc-v9-chinese-traditional-900.woff') format('woff'); /* Modern Browsers */
//}
