
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-touch-callout: default !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  touch-action: none;
  > img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    user-select: none !important;
  }
}
